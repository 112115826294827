function setEqualHeight(){
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .product-options").matchHeight();
    $(".grid-product .productshortdesc").matchHeight();
    $(".grid-product .productshortattributes").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".grid-product .nakup").matchHeight();
    $(".news-image-wrapper,.news-content-wrapper").matchHeight();
}

$(window).load(function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

$(".scroll-to-anchor").click(function(event) {
    event.preventDefault();
    var $target = $($(this).attr("href"));
    $("html, body").animate({scrollTop: $target.offset().top}, 500);
    return false;
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

$(window).bind('scroll', function () {
    var scroll = 120,
        skelBreakpoints = skel.vars.stateId;
    if (skelBreakpoints.search("/medium") > -1) {
        scroll = 114;
    }
    if (skelBreakpoints.search("/xsmall") > -1) {
        scroll = 181;
    }
    if ($(window).scrollTop() > scroll) {
        $('#nav-wrapper').addClass('fixed');
    } else {
        $('#nav-wrapper').removeClass('fixed');
    }
});
